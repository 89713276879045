import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "シンプルで低コストな動画配信の実現：Cloudflare Stream 導入事例",
  "date": "2024-09-30T01:24:47.000Z",
  "slug": "entry/2024/09/30/102447",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "シンプルで低コストな動画配信の実現：Cloudflare Stream 導入事例"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。ジョブメドレーアカデミーの開発を担当している德永です。今回は、私たちのオンライン動画研修サービスで Cloudflare Stream を導入した経緯と、そのメリット・デメリットについて詳しくお話しします。`}</p>
    <h1>{`背景`}</h1>
    <p><a parentName="p" {...{
        "href": "https://jm-academy.jp/"
      }}>{`ジョブメドレーアカデミー`}</a>{`は、Web / モバイルアプリで提供している介護・障がい福祉・在宅医療事業者向けのオンライン動画研修サービスです。直近では複数の業種への提供を開始し、多くの職員の方々に利用していただけるプロダクトに成長しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://jm-academy.jp/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1032px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACe0lEQVQoz12Ty4qkRRCF070bcVUzb+Lr+Dy+gQvB3khvhIER1I2MioMwCiPDMGNX36q7qv5L3iIzIy9/xpG/emjRxcchMuAQcZJQo41qsuHTmXgYiTFRWjRxd6l0Gx+YfeqaUjch/5+mfcTs083g+WMAH6mjSWqw/IQ4x5k8AifJpYBiAoWEmDLq0lHqgtpWOsoHra1LyYyUiwagQm5KjaGpKS4bHxLd73awxgiFKHqahKwWclakFcHSJOcivRaRVqWVLCFm8bHgqCmPJpyHVL5czZROfeMo0u3tLay1wsz4/o8rnP96ge9eXeObn9/h+asbXB4dzn95j2e/X+Lb3y5wcTdCGyfr5KUUWOugNIuyBRvrA223W8zzfDJ8eTHjh9d7vPx7POlPb4640xE//nXAi7cDXrwbcX8wwtMWRFo4hea9a+roixp83lBkOh4PMMZIWPNzDsEaLLUCEEA6SqknRe+ACMyksd/+id3uWpz34Myi5rgow7KxFGi3e1g5xoT95Q2MI+TSwB9YP2bV9S3mCqsNpnnGXs/ItSDnDDX4okaqGx8TDcPxtHJKCTYwZh/BpUJE0LugywNrvfQOM824u73Cbjig1HrKUV0NXu1NfBK5UGbGsiySSxUXWEIqknIVLk0iV4lcHglcxY5axv0Os7dSThMylPZJvbkePsl1sQ9hodbW2/Fw35ynZh01Y33jXFsu7T8Y47qeBlhrpNa6GorqXZ76mD9zMYNSAaUMv2qMcCHB+AjtA3zKIC6PRK64mzX2815kaViDKCVDpVw/d8RfGM9fa5/O/iWfWcpnhvhUTzaezS4+9icbv6LAV0QB1vnOOa8X0/8BDqp2LMan3swAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ジョブメドレーアカデミー",
            "title": "ジョブメドレーアカデミー",
            "src": "/static/49c16ec9d9620fa4774bba8dbe7e26be/12bff/job-medley-academy.png",
            "srcSet": ["/static/49c16ec9d9620fa4774bba8dbe7e26be/5a46d/job-medley-academy.png 300w", "/static/49c16ec9d9620fa4774bba8dbe7e26be/0a47e/job-medley-academy.png 600w", "/static/49c16ec9d9620fa4774bba8dbe7e26be/12bff/job-medley-academy.png 1032w"],
            "sizes": "(max-width: 1032px) 100vw, 1032px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`要件に合った動画配信プラットフォームを探す`}</h2>
    <p>{`ジョブメドレーアカデミーでは、外部の動画配信プラットフォームに研修動画をアップロードして、発行される配信用の URL をプロダクト内で利用し、サービス提供を行っています。これは動画配信基盤をメンテナンスするコストを削減し、動画配信の安定性を確保出来ることを目的としています。`}</p>
    <p>{`もともと利用していた動画配信プラットフォームは、非常に機能が豊富で様々なユースケース・プロダクトに利用することが可能でした。しかし、豊富な機能が提供されていてもジョブメドレーアカデミーで必要とされているのは主に動画の配信のみであり、その他の機能はほとんど必要ありませんでした。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a868bec9edf5ad42cb7d363182cb9ef1/e85cb/job-medley-academy-viewing-video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACn0lEQVQozzWNXUiTYQBGv5sugyK6Sa+EKKIUhAyKCilKJCpIkqSWRYH9YGb+pPZnlpGl4kxzRi4rarpZy7k1R26u1BmWZq10fDOtXF+bGhE558z3hEIHDs/deaQxW40yYavCby4X3dWnhe6iCmeDBovRiEmnx93dRX+Pi1AwyBzhmdl5g1PT/JmcmlsxbzDE1PSMIvmelRKwqMWEtZLf1nLCnfXUXsplxapoCmuaOHVFw+XbDdw1WDG/eIU8NMwPf4CpUHj+YC48GQwxHf4rxsYmkPyGi0JpPIfSUCAC1lvC57SwMTqazLP5BGfBMzqOvVemztRB8f1WMssbaDSaaTFbeG61EQyGRHhmVvh8Coo/ICT/06vCr8tFacwXHlsTmtxMli5ahLm+Cp+rmaHeTj7II/S5B3k/OMSwMs6vn+O0tztYFhGJ++MAyvcfwivLzIKQlOYyvhuKGHIaeVlXxfaYVWzYHM9wnxNHVQHW+krs3e9oc/Xx2u2l600/T3R6CgsLWbt6DY5HWhwmA/+RRpvV4pvLgsfVLmS7iWsZxzmWlkZP3zs+ve3B+9WHe8RPz0cvnm9+9HojqamniIldy6E9u5BfmHDUFgm7QYvs/Syk4Q6rGGhv5YOlSfS36EWHthJD8TkuH9nP4b1JZJ3ORvvgMa2dvQyOBlBXVJOVkU1EZCSP1DfovFctnDdPCkveDq4lrxOS/a4a063rorGsmIqMNLJ3buNM4iaKk7eQk7CZM1vjuLFvHRdS4ik7n4Uq5QBbtyWQcyINb5uZ+zlH8bTUMfZSJ748vYlUckyl5KmSSd+9Q6jWx4nYJYtZvkAiMWohJzdFkZ+wkpKkGOqObMClLUObm07JiUN86WjDrimlNvOg8DRrhGy6w2fbQ+Uf7OET3CQ1d/kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ジョブメドレーアカデミーの動画",
            "title": "ジョブメドレーアカデミーの動画",
            "src": "/static/a868bec9edf5ad42cb7d363182cb9ef1/e85cb/job-medley-academy-viewing-video.png",
            "srcSet": ["/static/a868bec9edf5ad42cb7d363182cb9ef1/5a46d/job-medley-academy-viewing-video.png 300w", "/static/a868bec9edf5ad42cb7d363182cb9ef1/e85cb/job-medley-academy-viewing-video.png 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`また、プロダクトの急成長に伴って動画配信費用の増大 / 定期的な動画配信容量の見積もりやそれに伴う商談といった、サービス利用の上で発生するコスト面に課題を感じていました。`}</p>
    <p>{`そこで、動画配信プラットフォームの移行を検討することにしました。移行先の動画配信プラットフォームを選定する際、私たちが重視したのは以下のポイントです。`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`シンプルな運用`}</strong>{`：専任のインフラエンジニアがいなくても簡単に運用できること`}</li>
      <li parentName="ul"><strong parentName="li">{`コスト効率`}</strong>{`：使った分だけの料金が発生し、予算管理がしやすいこと`}</li>
      <li parentName="ul"><strong parentName="li">{`機能面`}</strong>{`：高度な機能は不要で、動画配信に必要な最低限の機能があること`}</li>
    </ul>
    <p>{`これらの要件を満たすサービスを検討した結果、私たちは Cloudflare Stream を採用することにしました。`}</p>
    <h1>{`競合サービスとの比較`}</h1>
    <p>{`ジョブメドレーアカデミーでは、Cloudflare Stream 導入前にいくつかの動画配信プラットフォームを検討しました。その中でも、特に比較検討したのが ULIZA と AWS Media Services でした。`}</p>
    <h2>{`ULIZA`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.uliza.jp/"
      }}>{`ULIZA`}</a>{` は日本企業が提供する動画配信プラットフォームで、日本語でのサポートが充実している点が魅力でした。また、動画の編集、配信、分析など、多岐にわたる機能を提供しており、Web サービス上で CMS 機能も利用できるため、多くのユースケースに対応できる柔軟性があります。`}</p>
    <p>{`しかし、ジョブメドレーアカデミーでは、動画配信の基本的な機能のみが必要であり、ULIZA のようなリッチな機能は必ずしも必要ではありませんでした。また、料金体系も Cloudflare Stream と比較して高額であったため、コスト面での懸念がありました。`}</p>
    <h2>{`AWS Media Services`}</h2>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/media-services/"
      }}>{`AWS Media Services`}</a>{` は、AWS 上で動画配信インフラを構築できるサービスです。柔軟性が高く、プロダクトの要件に合わせてカスタマイズできる点が魅力ですが、インフラ構築や運用には開発工数・保守運用コストを要するため、トータルでのコストが高くなる可能性があります。`}</p>
    <p>{`ジョブメドレーアカデミーでは、前述の通りインフラ専任のエンジニアがいないため、運用負荷の低いサーバーレスな動画配信プラットフォームが求められていました。そのため、AWS Media Services は採用を見送ることになりました。`}</p>
    <h1>{`Cloudflare Stream を採用した理由`}</h1>
    <p>{`Cloudflare Stream の採用を決定した理由には、いくつかの要素があります。`}</p>
    <h2>{`シンプルな料金体系`}</h2>
    <p>{`Cloudflare Stream は、動画の配信時間と保存時間に基づくシンプルな従量課金制です。`}</p>
    <ul>
      <li parentName="ul">{`配信費用： 1,000 分あたり 1 ドル`}</li>
      <li parentName="ul">{`保存費用： 1,000 分あたり 5 ドル`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://developers.cloudflare.com/stream/pricing/"
        }}>{`Pricing | Cloudflare Stream docs`}</a></p>
    </blockquote>
    <p>{`他の動画配信プラットフォームと比べてかなり明瞭な料金体系となっており、予算管理を行いやすいのが特徴です。課金形態が少し異なるため比較しづらいのですが、ジョブメドレーアカデミーのケースでは競合サービスと比較しても安価な料金での利用が可能でした。`}</p>
    <p>{`ジョブメドレーアカデミーでは、7,500 本以上の動画を提供しており、月額で約 390 ドルの保存費用 + 再生時間による配信費用が発生しています。この料金体系のおかげで、今後のコストを明確に予測でき、予算に応じた運用が可能になりました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2e493add76edaf1ae9bbd693e1fd7a7b/ee515/cloudflare-stream-pricing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVQoz4WQ22qEQBBE/X/ySXlKPiBvvqwhEiQrRufSXnAd9YSeJZLdRFNQDA3TVdWVrOvKsiy/OE3Txnvozh4SXR6Ggb7vN+qc5zlpmpJlGeM48tP4SDTZc9dkVVVRliVFUdA0Dd57uq7b/t0zCh7FV2giFTXGRFFNvwfVSuZ5pm1bpO0QaXHO47xgncREShGJglpHCCGm/36vDMyLpoQkhImPc8n7uaE2grGe2gqfRnDOYa2NYkqd1UBfNbkaCuOgNSy3Hf53+u6ZS6B0E08neKvX2w7/KvqQwGUQXl6Fh0d4Pq18AeSLcSQjK973AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cloudflare Stream 料金体系",
            "title": "Cloudflare Stream 料金体系",
            "src": "/static/2e493add76edaf1ae9bbd693e1fd7a7b/c1b63/cloudflare-stream-pricing.png",
            "srcSet": ["/static/2e493add76edaf1ae9bbd693e1fd7a7b/5a46d/cloudflare-stream-pricing.png 300w", "/static/2e493add76edaf1ae9bbd693e1fd7a7b/0a47e/cloudflare-stream-pricing.png 600w", "/static/2e493add76edaf1ae9bbd693e1fd7a7b/c1b63/cloudflare-stream-pricing.png 1200w", "/static/2e493add76edaf1ae9bbd693e1fd7a7b/ee515/cloudflare-stream-pricing.png 1269w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`実際の課金時の画面`}</em></p>
    <h2>{`高品質な動画を安定して提供`}</h2>
    <p>{`Cloudflare Stream では、ユーザーが高画質・低画質どちらで視聴しても料金が変わらないという特長があります。特に、私たちのような研修サービスでは、動画内のテキストや細かい部分の可読性が重要となってくる場合もあります。以前利用していたサービスでは、データ転送量によって料金が変動したため、高画質の動画を提供する際にコスト面での懸念がありましたが、Cloudflare Stream ではその心配がなく、安定した高画質の動画配信が可能になりました。`}</p>
    <h2>{`移行コストの低さ`}</h2>
    <p>{`ジョブメドレーアカデミーでは前述の通り、もともとは別の動画配信プラットフォームを利用していました。そちらから移行する作業も簡単に実現可能です。`}</p>
    <p>{`プロダクト上で問題なく動画が視聴できるかの確認は、Web 上で設定や管理が完結するため、動画をアップロードしただけで配信用の URL なども発行され、インフラ専任のエンジニアがいなくても問題なく検証に進むことが可能でした。`}</p>
    <p>{`実際の動画ファイル移行についても、`}<a parentName="p" {...{
        "href": "https://developers.cloudflare.com/api/operations/stream-videos-upload-videos-from-a-url"
      }}>{`アクセス可能な URL から自動で取り込む機能を API として提供している`}</a>{`ため、既存の動画プラットフォームで期限付きのリンクを発行し、Cloudflare Stream API 経由で動画配信の URL を渡すだけでアップロードすることが可能です。`}</p>
    <h2>{`開発者体験の良さ`}</h2>
    <p>{`開発者体験という面でも、 Cloudflare Stream の魅力は非常に大きかったです。また、Go、TypeScript、Python 向けの SDK が提供されており、API を使った開発が非常にスムーズに進みました。`}</p>
    <p>{`さらに、専用プレイヤーの提供もあるため、動画の視聴環境を迅速に構築できました。`}<a parentName="p" {...{
        "href": "https://developers.cloudflare.com/api/operations/stream-videos-list-videos"
      }}>{`API ドキュメント`}</a>{`も非常に充実しており、SDK が未対応の言語でも容易に実装が可能です。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0084ad9cde7c973b4320a4b195f34146/2e5e9/cloudflare-stream-api-reference.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACZklEQVQ4y3VU2W7cMAzM//9eH4pik2Bje33Kun0fUwy9drZoK4CgD2k4HJJ6WzqLEALmeQbXvu/Ytg1aOxjrEWKH2A1/WdfT9/JsXUQ/THL+rVEKVV0jxg7GOYzjJIC3+wdu7+9oGoVt37Gsq9i8LIfNs+wjgdMEsO97KKVgjEY3jFi3TexHeoP14cniYOJ8hLFOvHUBy7LidRFUAJ1zWNcV0zSJ37cdMQRYa6HbFm3bous6DMNw7eM/Y4x85/spmQBqrWXjuizit21HXuQS6Iz8uvp+xO32gZ+/bkjSHGmWwzp/ADIqU/bOYZhmSZeL2tVNA+c8hnEUbQdKQobjhLIsUdYKSltJn/8E0HsvgDFGrOuhHxmVZYUkzZAkKfK8QFnVEoQHSaIoKhhjMQ6jFJTfBJBaEPQpq4ASkJuVaqUI3bMwbKFpnkWzNGvEmIFzQUAvhjQpxr5fvqpqpGmGLHvIc90oNE0rDAmoWov0QVkMQohSHBIShhT/7KnTa22glBamZMFDtGmaBdQYh3tSI81rJI8KxoWDIbUj+jiOWJblAmS6j0eOVhsBtNYJ+AE4yCR93msoZdBqi/4sCgFpBCMQ02GXsABFUYq17cGUrI9qE9AiSRsUlUZeKhnFP6p8ascGpacMZEXm7MvDNjYlhn4QVl9pgySrcU/Lb4a8GNjxB7PjEI19xkBkfo3WtgLbgv4JmGRK2H1lFVzovgGd91fKp4YhRvgQJb3xGewcGO45JuroW7YaM3gCepnbM9WTIVmTIUfzGr+XW+VfSy4HF0cYP2Cal+saIiA1JDsZ/Gfar1fV/+w3/s4kNVxDXVwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cloudflare Stream API Reference",
            "title": "Cloudflare Stream API Reference",
            "src": "/static/0084ad9cde7c973b4320a4b195f34146/c1b63/cloudflare-stream-api-reference.png",
            "srcSet": ["/static/0084ad9cde7c973b4320a4b195f34146/5a46d/cloudflare-stream-api-reference.png 300w", "/static/0084ad9cde7c973b4320a4b195f34146/0a47e/cloudflare-stream-api-reference.png 600w", "/static/0084ad9cde7c973b4320a4b195f34146/c1b63/cloudflare-stream-api-reference.png 1200w", "/static/0084ad9cde7c973b4320a4b195f34146/d61c2/cloudflare-stream-api-reference.png 1800w", "/static/0084ad9cde7c973b4320a4b195f34146/2e5e9/cloudflare-stream-api-reference.png 2347w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Example なども充実した API ドキュメント`}</em></p>
    <h1>{`実際に導入しての所感`}</h1>
    <p>{`Cloudflare Stream を実際に運用してみて感じたメリットと、触ってみるまで判明しなかった課題についていくつかご紹介します。`}</p>
    <h2>{`コスト面での満足度 ⭕`}</h2>
    <p>{`期待していた通り、シンプルな料金体系で予算管理が非常に容易でした。また、記載されている料金以外の追加課金が発生せず、コスト面での不安は感じていません。`}</p>
    <h2>{`安定性 ⭕`}</h2>
    <p>{`導入してから今まで、Cloudflare Stream の安定性に課題感はありません。障害情報は `}<a parentName="p" {...{
        "href": "https://www.cloudflarestatus.com/"
      }}>{`Cloudflare Status`}</a>{` で確認できるため、運用の際の不安要素も少なく済んでいます。`}</p>
    <h2>{`運用面での評価 💭`}</h2>
    <p>{`以前利用していたサービスでは、利用帯域幅の監視やプラン変更の手続き・次回契約のための想定帯域幅算出に工数がかかっていましたが、Cloudflare Stream ではそれが不要になり、運用工数を削減できました。`}</p>
    <p>{`一方、CMS 機能が最小限に抑えられている点は導入において課題になりやすい点です。例えば、動画のディレクトリ管理ができないため、ID ベースでの管理を自前で設計する必要があります。また、検索機能なども最低限しか提供されていないため、基本的に私達のサービスでは、 Cloudflare Stream 上にアップロードされた動画の meta フィールドに API 経由でアクセスし、動画の種別管理（テスト用の動画 / 本番用の動画など）を行っています。`}</p>
    <p>{`その他にも、 Cloudflare 側で提供されているアラートの粒度が粗いのは課題としてありました。配信容量と異なり保存容量は月単位で事前購入する課金形態です。そのため、ユーザーの利用状況に合わせて残容量が減ってきたら容量を購入する必要があります。完全にサービス提供側でアップロードする動画を管理できる場合は問題にならないのですが、ジョブメドレーアカデミーではユーザーが動画をアップロードするユースケースも存在するため、容量の監視が必要です。`}</p>
    <p>{`Cloudflare が提供している機能として容量の上限が近づくとメールが届くのですが、割合での通知のため、総利用容量が増えた場合は「残り 数%ではあるが、容量の実数（nGB アップロード可能か）を見ると当分問題ない範囲である」ということが多々あり、実際に容量を購入しないと不足してしまうタイミングがいつかわかりません。Cloudflare Stream 側で残容量を取得する API が提供されているため、動画アップロード時にこの API を利用して容量確認を行い、残容量が少なくなった場合に Slack 通知を行うように開発を行いました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "656px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a9d897c09aae059990ff45813117e1df/748f4/cloudflare-stream-alert-mail.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsklEQVQ4y4VTi07DMAzcB/Nl/AUwgQTfwKZN2qvpu0m7tk4OnbeUwQaL5Nlz3btz7M5kMYes3qF++Qqs3s72Hcvi5Y7NIZ/PGFcfmAGAz9foHh/QL56QOcE+q2CqFmnVwpQOt074IzfjT8iW8MtXSP6JLDUwyQF5liI1iXrAAyGanHyE0PjkvYyYheDRDR6tAL0A4ziiLEscj0c0TYO6rtF1HZxz6odhgLUWh8MBVVVpnvV8L4RAwICqLFVZagzW6zX2+z2yLEOapmoEJUCe52okI1Df90rA/8Tx3p8AyUi2aFRGT0UsjmqYL4pC6xkTjGRJkmg8KWQRjewiovJpZKSxWPMiGM7P4nN6Kr1SSDW8C2OM+rZtlZ1KGFvr4LsaaAuE3gF9g6GtJ7UknBQSjC2xPbIwFxmjnYbqp6kGLz9qJoWsoyIOguo2mw12u51eAfPb7VZzHM5oc/TFDoMt4F2OcKR6p2Ji2woYW2Zr9JdrEidK8HgFjbUYR7la7OkOf2z7WXps+78Ta2LdBMid4+jZFluMK0JFv1/8DXITkGsRv4JpABcq7p0rwHut3Gv/EvAL0J2M/DW77AYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "実際に送信されたメール",
            "title": "実際に送信されたメール",
            "src": "/static/a9d897c09aae059990ff45813117e1df/748f4/cloudflare-stream-alert-mail.png",
            "srcSet": ["/static/a9d897c09aae059990ff45813117e1df/5a46d/cloudflare-stream-alert-mail.png 300w", "/static/a9d897c09aae059990ff45813117e1df/0a47e/cloudflare-stream-alert-mail.png 600w", "/static/a9d897c09aae059990ff45813117e1df/748f4/cloudflare-stream-alert-mail.png 656w"],
            "sizes": "(max-width: 656px) 100vw, 656px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`実際に送信されるメール通知。3,322 分あれば直近は充分なのだが、毎日通知が来る`}</em></p>
    <p>{`また、Web ダッシュボード上でのファイル管理が限定的であるため、他の動画配信プラットフォーム（ULIZA などのリッチな機能を持つ動画配信プラットフォーム）に比べて管理の手間がかかる部分もあります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0d1ae00358a031b65e73e654db338455/04aae/cloudflare-stream-dashboard.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQoz2VSW26DQAzk/v/lrzlDj1D6mfYEjYgUqVKVPlYEln147Z3KSyDQjGRhYXs89rpqD+94aZ7hvIci5wwigrW2+CJSvmoKjbVti9PpBGNMiaeUwMzFqn4khEjXIkxFKeHb9AiRkRIvxAol2e0eUdcPaJpmETGjGsYAigF5CUxqiDNiksWYBZSkNDibERcbIMwL4WyVDwQR3nSaggIiHSUj8UyqfsbXJZWG/9UVwn6McCFugjqeKlK1H4bxYyflkjPs6PDbE1iyFmzUTSM7QqS0+amo6xqv+z0GLzCXsRQP0aH3dmqOvJnotkNH8CTXB7kl6PJ9CPMw06NkQhYuuet9r1EN1iMGv1muwjmHruswWFtOxfmIpzeL43nOxR1ZIfQxLaexJtTbCiEUU1/XcvgM6CzdNV8T/gGGsL7GT5mU8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cloudflare Stream ダッシュボード画面",
            "title": "Cloudflare Stream ダッシュボード画面",
            "src": "/static/0d1ae00358a031b65e73e654db338455/c1b63/cloudflare-stream-dashboard.png",
            "srcSet": ["/static/0d1ae00358a031b65e73e654db338455/5a46d/cloudflare-stream-dashboard.png 300w", "/static/0d1ae00358a031b65e73e654db338455/0a47e/cloudflare-stream-dashboard.png 600w", "/static/0d1ae00358a031b65e73e654db338455/c1b63/cloudflare-stream-dashboard.png 1200w", "/static/0d1ae00358a031b65e73e654db338455/d61c2/cloudflare-stream-dashboard.png 1800w", "/static/0d1ae00358a031b65e73e654db338455/97a96/cloudflare-stream-dashboard.png 2400w", "/static/0d1ae00358a031b65e73e654db338455/04aae/cloudflare-stream-dashboard.png 2463w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`実際のダッシュボード画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/718a795024028ce7ded3dde5f3fe5576/045f9/cloudflare-stream-video-detail.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4ElEQVQoz32SzW7TQBSFrShEUVbZkHZtJJoHi/ICwI6ukzVVKuARsi5SXiBSWSEQqhQl2EFJ0zamsccej+358UEzxa5NEVcaeUbnzudz71zr+9cv+Hx5Cc45dOR5jjTjiCgFpRRJkhgtVzmkkqAsxm63g+d5CIIAQgizlFJgjME6kNhc1qAiYsZACDHAKIqQZVmpORsX5+cTTCYTTKdTSClLLU1TWJSlkFJA8wpoph1GtHTtui4WiwVWqxVubm7hbm6xub6D7/vmTnHPAJMkRa6BeARqUBiGxpl2MJ/PcXHxCbPZDIulgzAFhMKfFqEOJDQFF9IoVaHokS57v99ju91id73FwSfwQg4hVOmuBqQsA+eiJugGK/XYm2roDClV+YDVrwFGTEAbVBVgcLjHj+US7npt+uc4Ln6uHXy7WuHK/fUEVi85Ysj4w6NIIYzw7uwMrVYLvV4P3W7XLL1vtZ7hzetXJkePyj9LZvqVBS+TdIzHY1iWZVa73Uan0ynPw+Gwlvuk5IxLKPXwlyJpNBqVgEajgWazWZ4Hg8H/gbrNhe1iSN9/+IjnvWO8PDnBC9uGbdvo9/s4OjrG29NTk1Md6Bqw2odCiFmKO883I6LnkZAQISE4+AFozGqQv4G/AXKFVdsq5OXQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cloudflare Stream 動画詳細画面",
            "title": "Cloudflare Stream 動画詳細画面",
            "src": "/static/718a795024028ce7ded3dde5f3fe5576/c1b63/cloudflare-stream-video-detail.png",
            "srcSet": ["/static/718a795024028ce7ded3dde5f3fe5576/5a46d/cloudflare-stream-video-detail.png 300w", "/static/718a795024028ce7ded3dde5f3fe5576/0a47e/cloudflare-stream-video-detail.png 600w", "/static/718a795024028ce7ded3dde5f3fe5576/c1b63/cloudflare-stream-video-detail.png 1200w", "/static/718a795024028ce7ded3dde5f3fe5576/d61c2/cloudflare-stream-video-detail.png 1800w", "/static/718a795024028ce7ded3dde5f3fe5576/045f9/cloudflare-stream-video-detail.png 2350w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`実際の動画詳細画面`}</em></p>
    <h2>{`機能面での評価 💭`}</h2>
    <p>{`基本的には期待していた通り、動画配信の実装をシンプルに実現することができました。しかし、一部のケースにおいて `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com/ja-jp/learning/video/what-is-http-live-streaming/"
      }}>{`HLS`}</a>{` / `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com/ja-jp/learning/video/what-is-mpeg-dash/"
      }}>{`MPEG-DASH`}</a>{` を使った動画配信への対応に工数がかかりました。`}</p>
    <p>{`HLS / MPEG-DASH での配信は`}<a parentName="p" {...{
        "href": "https://www.cloudflare.com/ja-jp/learning/video/what-is-adaptive-bitrate-streaming/"
      }}>{`アダプティブ・ビットレート・ストリーミング`}</a>{`が基本となり、視聴者のネットワーク環境によって動的に配信される画質が決まります。この機能はネットワーク環境に最適な画質が自動で選択されるため、動画を視聴する職員の方々にとってより良い視聴体験を提供することに繋がりました。`}</p>
    <p>{`一方、この機能を利用すると一部の動画プレイヤーでは視聴端末のネットワーク環境に適した最高画質でのみ配信され、画質がプレイヤー上で選択出来ないという問題がありました。ジョブメドレーアカデミーの動作環境の中では、iOS Safari x Stream Player や React Native の Expo Player が該当します。`}</p>
    <p>{`ジョブメドレーアカデミーでは、私用スマホを利用して動画研修を受講される職員の方が Wi-Fi 環境を用意できず、データ通信量を節約するために画質を下げて視聴するというケースがあります。自動的に最高画質での配信が行われてしまう場合、意図せずデータ通信量が増大してしまうことで一部の職員の方にとって不利益が生じる可能性があります。`}</p>
    <p>{`以前まで利用していた動画配信プラットフォーム上では、mp4 ファイルで複数画質の動画が提供されていたため、ダウンロードする mp4 ファイルの画質を選択することで視聴する職員の方が画質を制御することが出来ました。一方、 Cloudflare Stream では mp4 ファイルでの配信自体は設定により可能ですが、配信される mp4 の画質は常に最高画質での配信に固定されるため、今回の画質を選択したいというケースには利用できませんでした。`}</p>
    <p>{`また、 mp4 を利用すると動画配信が発生した段階で動画全体の再生時間が課金対象になります。例えば 30 分の動画を 1 分だけ視聴して配信画面を閉じた場合、 HLS / MPEG-DASH だと 1 分が配信費用の課金対象として計上されるのに対し、 mp4 での配信だと 30 分が配信費用の課金対象として計上されてしまいます。利用状況によってはコストがかさむ可能性があるため、注意が必要です。`}</p>
    <p>{`この配信する画質についての課題は、 Cloudflare Stream で提供されている動画配信時に`}<a parentName="p" {...{
        "href": "https://developers.cloudflare.com/stream/viewing-videos/using-own-player/#customize-manifests-by-specifying-available-client-bandwidth"
      }}>{`画質を制御するオプション（clientBandwidthHint）`}</a>{`を利用することで解決することが出来ました。このオプションは最終手段であるという旨がドキュメント内に記載されていますが、今回のケースでの利用は妥当だということを Cloudflare Stream の開発者である Zaid Saleem 氏に X 上で確認済みです。`}</p>
    <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Makes sense. Have you already tried clientBandwidthHint feature of Stream? <a href="https://t.co/6KAf94MNuR">pic.twitter.com/6KAf94MNuR</a></p>&mdash; Zaid Saleem (@zaid) <a href="https://twitter.com/zaid/status/1791274100729471168?ref_src=twsrc%5Etfw">May 17, 2024</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`結果的に画質を選択する機能を提供することが出来ました。が、Cloudflare Stream が提供する配信方法の仕様上仕方ないところではありつつ、他の動画配信プラットフォームで提供している mp4 の特定の解像度を配信する方法と比較すると、直感的ではなく開発工数がかかってしまいました。`}</p>
    <details>
      <summary>clientBandwidthHint と解像度の対応表（ジョブメドレーアカデミーのフルHD動画で検証したデータ）</summary>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`clientBandwidthHint`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`画質`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.1`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`240p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.2`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`360p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.3`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`480p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.4`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`480p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`720p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.6`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`720p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`720p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.8`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`720p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`0.9`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`720p`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`1.0`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1080p`}</td>
          </tr>
        </tbody>
      </table>
    </details>
    <h1>{`Cloudflare Stream の採用を検討している方へ`}</h1>
    <p>{`Cloudflare Stream を導入するメリットは大きいですが、サービスの特性上、すべてのケースに適しているわけではありません。以下のポイントを踏まえて検討することをお勧めします。`}</p>
    <h2>{`Cloudflare Stream が向いているケース`}</h2>
    <ul>
      <li parentName="ul">{`シンプルで予測可能な料金体系を希望している`}</li>
      <li parentName="ul">{`高品質な動画を安定して提供したい`}</li>
      <li parentName="ul">{`開発リソースや運用リソースが限られている`}</li>
      <li parentName="ul">{`開発者向けのサポートやドキュメントが充実している環境を求めている`}</li>
    </ul>
    <p>{`特に、Cloudflare 内の他サービス（Workers や Pages など）を利用している場合、Stream との組み合わせでコスト削減や運用の効率化が期待できます。`}</p>
    <h2>{`Cloudflare Stream が向いていないケース`}</h2>
    <p>{`一方で、以下のようなケースでは他のサービスを検討することも選択肢に入れるべきです。`}</p>
    <h3>{`動画サイズが 30GB を超えるような動画が必要な場合`}</h3>
    <p>{`Cloudflare Stream では、1 動画あたり最大 30GB のアップロード制限があります。長時間の高解像度動画を扱う場合は、この制限に注意が必要です。`}</p>
    <blockquote>
      <p parentName="blockquote">{`By default, a video upload can be at most 30 GB.`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
          "href": "https://developers.cloudflare.com/stream/faq/#is-there-a-limit-to-the-amount-of-videos-i-can-upload"
        }}>{`Is there a limit to the amount of videos I can upload? | Frequently asked questions about Cloudflare Stream`}</a></p>
    </blockquote>
    <h3>{`高度な分析機能が必要な場合`}</h3>
    <p>{`Cloudflare Stream の分析機能はミニマムなものであるため、視聴履歴や詳細な視聴データの分析は外部ツールで行う必要がある場合があります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/979f3e5049fba46a67d6802f50e6bbd0/7ad71/cloudflare-stream-analytics.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqUlEQVQ4y51U207DMAzd///A3vYxICHBQJo2aQ9MbGyCh13Y2iakuR9ktw0Z5SJmyUqaOifHPk4GD7c3KMoSZDFGduccnLUIIaQ1cjLvPTsZ/TfGsFtrURQFBo+LJ0gpEyAF7fYH7HZ7eOcSWAcyHt9hOBxiNBphs9kkYDIhBAbG+gTWjbL2sI6CPtkRAwJdrp5xdX2D8f09M8oBq6rCwBp9llbD0iPGALTezCMfIHVEqZEsLwcz9CFnwhHQxkKZAG0j6taViXA+4iQsdoVBbOubZ8eAlax7DI21nDIBuNAAkfsQoW1oy4GeYA1D73snkRiBRYg8NjEhqZ7H9hiK2iPw97kAWmsuMs1pTSnFG7rSfM0qAdLGPIBsvV5juVzidDox0Gq1wnQ6xWw2S+3zI8N3VffU2m63DNjN5/M5JpMJFovF3ynnQPnPsixwPB5xOBwYnG5Dl/KvgOdqNW0jlcZ2X0AK0TasYEGEqP5OOWfYjUrVKMuKWXVrznkobf+X8me6JV5fXliU2N4Qar03GS4DdO2j0Kyhva+AMrhMlO6J6j1f3xz+FfADfqjlCVD6jpoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cloudflare Stream 分析画面",
            "title": "Cloudflare Stream 分析画面",
            "src": "/static/979f3e5049fba46a67d6802f50e6bbd0/c1b63/cloudflare-stream-analytics.png",
            "srcSet": ["/static/979f3e5049fba46a67d6802f50e6bbd0/5a46d/cloudflare-stream-analytics.png 300w", "/static/979f3e5049fba46a67d6802f50e6bbd0/0a47e/cloudflare-stream-analytics.png 600w", "/static/979f3e5049fba46a67d6802f50e6bbd0/c1b63/cloudflare-stream-analytics.png 1200w", "/static/979f3e5049fba46a67d6802f50e6bbd0/d61c2/cloudflare-stream-analytics.png 1800w", "/static/979f3e5049fba46a67d6802f50e6bbd0/7ad71/cloudflare-stream-analytics.png 2338w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`実際の分析画面 - 基本的に課金単位の再生時間に注目した、分析機能のみ提供されている`}</em></p>
    <h3>{`動画管理機能が求められる場合`}</h3>
    <p>{`前述の通り、 Cloudflare Stream は他サービスと比べ、CMS 機能は非常に限られています。動画のディレクトリ管理やタグ付けなど、細かい管理が必要な場合には、自前で管理システムを構築するか、他のサービスを選ぶ方が効率的です。`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`Cloudflare Stream のシンプルな料金体系と高品質な動画提供は大きな魅力です。一方で、CMS 機能や高度な分析機能を求める場合には、他のサービスも視野に入れる必要があります。`}</p>
    <p>{`動画配信プラットフォームの選定は、プロダクトの特性やチームの状況に応じて異なりますが、この記事がその一助になれば幸いです。今後もユーザー体験の向上とプロダクトの成長に向けて、最適な技術選定を続けていきたいと思います。`}</p>
    <hr></hr>
    <p><em parentName="p">{`2024 年 9 月時点の情報をもとに執筆しました。サービス内容や仕様は変更される可能性がありますので、最新情報は公式の情報をご参照ください。`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      